import React from "react";
import { useTranslation } from "react-i18next";
import { Team } from "../presenters/team";

export function TeamPage(props: {count: number, members: {[key: string]: string}}) {
    const { t } = useTranslation(["home", "team"]);

    return (
        <React.Fragment>
            <h1 className="text-middle underline large-title">
                {t("team-title", {ns: "home"})}
            </h1>
            <Team count={props.count} members={props.members}></Team>
        </React.Fragment>
    );
}