import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ProjectModel } from "./Project";


export function ProjectsPage(props: { projects: { [key: string]: ProjectModel } }) {
    const projectsArray = Object.keys(props.projects).map((key, i) => {
        const pp = Object.assign({}, props.projects[key]);
        pp.key = key;
        return pp;
    });

    const { t } = useTranslation(["projects", "home"]);

    return (
        <React.Fragment>
            <h1 className="underline text-middle large-title">
                {t("portfolio-title", {ns: "home"})}
            </h1>
            <div className="text-middle subtitle margin-bottom-s">
                {t("portfolio-subtitle", {ns: "home"})}
            </div>
            <div className="projects row">
                {
                    projectsArray.map((proj, i) => {
                        return (
                            <Link key={`portfolio-project-${i}`} to={`../project/${proj.key}`} className="col-4 portfolio-project ">
                                <h6>
                                    {t(`${proj.key}.title`, {ns: "projects"})}
                                </h6>
                                <div className="background">
                                    <img src={proj.thumbnail} />
                                </div>
                            </Link>
                        );

                    })
                }
            </div>
        </React.Fragment>
    );
}