import { useEffect, useState } from "react";

export function ReorderableGallery(props: {photos: any[]}) {
    
    const [state, setState] = useState<any[]>(props.photos);

    useEffect(() => {
        setState(props.photos);
    }, [props]);

    return (
        <div className="row">
            {state.map(i => {
                return (
                    <div className="col-6 contain-img">
                        <img src={i} alt="" />
                    </div>
                );
            })}
        </div>
    );
}