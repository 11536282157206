import './App.scss';
import { Header } from './presenters/header';
import { HashRouter as Router, Route, Routes, Navigate, useLocation  } from "react-router-dom";
import { Home } from './pages/Home';
import { Project, ProjectModel } from './pages/Project';
import { Footer } from './presenters/footer';
import { ProjectsPage } from './pages/Projects';
import { EditorModal } from './common/EditorModal';
import { TeamPage } from './pages/TeamPage';
import { ServicesPage } from './pages/Services';
import { ContactsPage } from './pages/Contacts';
import { useEffect } from 'react';
import { Rental } from './pages/Rental';

function ScrollToTop() {
	const { pathname } = useLocation();
  
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
  
	return (<button id="scrollToTop" onClick={() =>{ window.scrollTo(0, 0) }}></button>);
  }

function ContactUs() {
	return(
		<div id="contactUs">
			<a id="contactUsMail" href="mailto:novoselski@onon.productions">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"/></svg>
			</a>
			<a id="contactUsPhone" href="tel:+359879345581">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/></svg>
				</a>
		</div>
	)
}

function App(props: any) {
	const featuredProjects = (() => {
		const p = props.data.projects;
		const featured: ProjectModel[] = [];

		Object.keys(p).forEach(key => {
			const pp = p[key];
			pp.key = key;

			if (pp.isFeatured) {
				featured.push(pp);
			}
		});
		
		return featured;
	})();

	return (
		<Router basename="/">
			<ContactUs />
			<ScrollToTop />
			<EditorModal />
			<Header></Header>
			<main>
				<Routes>
					<Route path="/" element={<Home portfolio={{projects: featuredProjects}} clients={{clients: props.data.general.clients}} general={props.data.general}></Home>}></Route>
					<Route path="/home" element={<Navigate to="/"></Navigate>}></Route>
					<Route path="/projects" element={<ProjectsPage projects={props.data.projects || []}></ProjectsPage>}></Route>
					<Route path="/services" element={<ServicesPage general={props.data.general || []}></ServicesPage>}></Route>
					<Route path="/team" element={<TeamPage count={props.data.general.team.count} members={props.data.general.team.members}></TeamPage>}></Route>
					<Route path="/rental" element={<Rental data={props.data.rentals || []}></Rental>}></Route>
					<Route path="/contact" element={<ContactsPage></ContactsPage>}></Route>
					<Route path="/project/:projectId" element={<Project></Project>}></Route>
				</Routes>
			</main>
			<Footer></Footer>
		</Router>
	);
}

export default App;
