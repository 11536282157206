import { useEffect, useState } from "react";

export interface SizableVideoState {
    height: number;
    width: number;
}

export function AutoVideo(props: {src: string}) {
    
    const [state, setState] = useState<SizableVideoState>({ width: window.innerWidth, height: window.innerHeight });
    
    // useEffect(() => {
    //     setState(Object.assign({}, state, { width: ref.current?.getBoundingClientRect().width, height: calculateHeight(ref.current?.getBoundingClientRect().width) }));
    // }, [project]);

    useEffect(() => {
        function handleResize() {
            setState(Object.assign({}, state, {
                width: window.innerWidth,
                height: window.innerHeight
            }));
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <div className="autovideo">
            <video playsInline={true} autoPlay={true} loop={true} muted={true} src={props.src} width={state.width} height={state.height}></video>
        </div>
    )
}