import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { LocalStorageProvider, LKey } from './common/local-storage-cache';
import { initReactI18next } from 'react-i18next';
import { AssetsFetch } from './data/assets';
import { CMS } from './common/adorners';
import { createRoot } from 'react-dom/client'; 

const translations = AssetsFetch.getTranslations();
const data = AssetsFetch.getData();
let dataResolved: any = null;

Promise.all([translations, data]).then(([tra, dataRes]) => {
	dataResolved = dataRes;

	i18n
		.use(initReactI18next)
		.init({
			resources: {
				en: {
					header: tra.header.en,
					home: tra.home.en,
					projects: tra.projects.en,
					common: tra.common.en,
					services: tra.services.en,
					team: tra.team.en
				},
				bg: {
					header: tra.header.bg,
					home: tra.home.bg,
					projects: tra.projects.bg,
					common: tra.common.bg,
					services: tra.services.bg,
					team: tra.team.bg
				}
			},
			lng: LocalStorageProvider.getItem(LKey.language) || "en",
			fallbackLng: "en",
			interpolation: {
				escapeValue: false
			}
		});

}).finally(() => {
	const container = document.getElementById('root'); 
	const root = createRoot(container!); 
	
	root.render(<App data={dataResolved} />);

	CMS.attachAdorners();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
