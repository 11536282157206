import React from "react";
import { useTranslation } from "react-i18next"
import { adorn, EditableType } from "../common/adorners";
import { Clients, ClientsProps } from "../presenters/clients";
import { Portfolio, PortfolioProps } from "../presenters/portfolio";
import { AutoVideo } from "../presenters/video";

export interface HomeProps {
	portfolio: PortfolioProps;
	clients: ClientsProps;
	general: any;
}

export function Home(props: HomeProps) {
	const { t } = useTranslation("home");
	return (
		<React.Fragment>
			<div {...adorn("home-header", EditableType.image)} className="slogan-home" style={{ backgroundImage: `url(${props.general.homeHeader})` }}>
				<AutoVideo src={props.general.homeVideo}></AutoVideo>
				{/* <h1 {...adorn("home:slogan", EditableType.text)} className="large-title">{t("slogan")}</h1> */}
			</div>
			<div className="capabilities container">  {/* ??? */}
				<h2 {...adorn("home:capabilities-title", EditableType.text)} className="capabilities-title text-middle underline large-title">
					{t("capabilities-title")}
				</h2>
				<div {...adorn("home:capabilities-subtitle", EditableType.text)} className="capabilities-subtitle text-middle">
					{t("capabilities-subtitle")}
				</div>
				<div className="row">
					{[1, 2, 3].map((c, i) => {
						return (
						<div className="col-4" key={`capability-${i}`}>
							<div className="col-2 icon">
								{t(`capability-icon-${c}`)}
							</div>
							<div className="col-10">
								<h4 {...adorn(`home:capability-title-${c}`, EditableType.text)}>
									{t(`capability-title-${c}`)}
								</h4>
								<div className="capability-description" {...adorn(`home:capability-desc-${c}`, EditableType.text)}>
									{t(`capability-desc-${c}`)}
								</div>
								<div className="capability-list" {...adorn(`home:capability-list-${c}`, EditableType.text)}>
									{
										(() => {
											const list = t(`capability-list-${c}`).split(",");

											return (
												<ul>
													{
														list.map((l, j) => {
															return (
																<li key={`capability-${i}-${j}`}>{l}</li>
															)
														})
													}
												</ul>
											)
										})()
									}
								</div>
							</div>
						</div>);
					})}
				</div>
			</div>
			<div className="portfolio">
				<h2 {...adorn(`home:portfolio-title`, EditableType.text)} className="text-middle underline large-title">
					{t("portfolio-title")}
				</h2>
				<Portfolio {...props.portfolio}></Portfolio>
			</div>

			<Clients {...props.clients}></Clients>

		</React.Fragment>
	);
}
