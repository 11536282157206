import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { adorn, EditableType } from "../common/adorners";
import { ProjectModel } from "../pages/Project";

export interface PortfolioProps {
    projects: ProjectModel[];
}

export function Portfolio(props: PortfolioProps) {

    const { t } = useTranslation("projects");

    return (
        <div className="portfolio row">
            {
                props.projects.map((proj, i) => {
                    return (
                        <div key={`proj-${i}`} {...adorn(`project-edit-${proj.key}`, EditableType.project)} className="col-3">
                            <Link key={`portfolio-project-${i}`} to={`project/${proj.key}`} className="portfolio-project">
                                <h6>
                                    {t(`${proj.key}.title`)}
                                </h6>
                                <div className="background">
                                    <img src={proj.thumbnail} />
                                </div>
                            </Link>
                        </div>
                    );
                })
            }
        </div>
    );
}
