import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

interface NavItem {
    title: string,
    link?: string,
    children?: {title: string, link: string}[];
}

const menuConfig: NavItem[] = [
    { title: "home", link: "/" },
    { title: "projects", link: "/projects" },
    { title: "services", link: "/services"},
    { title: "team", link: "team" },
    { title: "rental", link: "rental" },
    { title: "contact", link: "contact" },
]

export function Header() {
    const { t, i18n } = useTranslation("header");

    const toggleRef = useRef(null)

    const closeMenu = () => {
        let ref;
        if (ref = toggleRef?.current) {
            (ref as HTMLInputElement).checked = false;
        }
    };

    return (<header>
        <div className="logo"><NavLink to="/"><img src="./general/ONON-logo.png" alt="On&On productions logo" /></NavLink></div>
        <input id="menuToggle" type="checkbox" ref={toggleRef} />
        <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
        </div>
        <nav>
            <ul>
                {menuConfig.map((item, i) => {
                    if (item.link) {
                        return (<li key={`nav-${i}`}>
                            <NavLink onClick={() => closeMenu()} to={item.link} className={({ isActive }) => isActive ? "active" : ""}>{t(item.title)}</NavLink>
                        </li>);
                    } else if (item.children) {
                        return (
                            <li key={`nav-${i}`} className="dropdown">
                                {t(item.title)}
                                <ul>
                                    {item.children.map((ch, k) => {
                                        return (<li key={`nav-${i}-${k}`}><Link to={ch.link}>{ch.title}</Link></li>);
                                    })}
                                </ul>
                            </li>
                        );
                    }
                })}
            </ul>
        </nav>
        {/* <div className="languages">
            <a onClick={() => i18n.changeLanguage('en')}>en</a>
            <a onClick={() => i18n.changeLanguage('bg')}>bg</a>
        </div> */}
    </header>);
}
