import React, { useEffect, useState } from "react";
import { ProjectModel } from "../pages/Project";
import { ReorderableGallery } from "./ReorderableGallery";

export function EditProject(props: { project: ProjectModel }) {

    const [state, setState] = useState<ProjectModel | null>(null);

    useEffect(() => {
        setState(props.project);
    }, [props]);

    const addVideo = () => {
        const cs = Object.assign({}, state);
        cs?.videos.push({ url: "" });
        setState(cs);
    };
debugger;
    return (
        <React.Fragment>

            <div className="row">
                <div className="col-2">Videos:</div>
            </div>
            {
                state?.videos.map(vid => {
                    return (<div className="row">
                        <div className="col-8">
                            <input type="text" value={vid.url} />
                        </div>
                    </div>);
                })
            }
            <button onClick={addVideo}>Add video</button>

            <div className="row">
                <h6 className="col-2">Title</h6>
                <input type="text" />
            </div>
            <div className="row">
                <h6 className="col-2">Sub title</h6>
                <input type="text" />
            </div>
            <div className="row">
                <h6 className="col-2">Client</h6>
                <input type="text" />
            </div>
            <div className="row">
                <h6 className="col-2">Date</h6>
                <input type="text" />
            </div>
            <div className="row">
                <h6 className="col-2">Project</h6>
                <input type="text" />
            </div>
            <ReorderableGallery photos={state?.photos || []}></ReorderableGallery>
        </React.Fragment>
    );
}