import { useTranslation } from "react-i18next";


export function Team(props: { count: number, members: {[key: string]: string} }) {
    const { t } = useTranslation("team")

    const members: any[] = [];

    for (let i = 0; i < props.count; i++) {
        members.push(
            (
                <div key={`team-${i}`} className="member">
                    <div className="member-background">
                        <div className="static">
                            <img src={`/team/${props.members[i]}.jpg`}/>
                        </div>
                        <div className="animated">
                            <img src={`/team/${props.members[i]}.gif`}/>
                        </div>
                    </div>
                    <div className="member-name">
                        {t(`members.${i}.name`)}
                    </div>
                    <img/>
                    <div className="member-bio">
                        {t(`members.${i}.bio`)}
                    </div>
                </div>
            )
        )
    }

    return (
        <div className="team container">
            {
                members
            }
        </div>
    )
}