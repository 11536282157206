import React from "react";
import { useTranslation } from "react-i18next";
import { adorn, EditableType } from "../common/adorners";

export interface ClientsProps {
    clients: string[]
}

export function Clients(props: ClientsProps) {
    const { t } = useTranslation("home");

    return (
        <div className="clients container">
            <div className="row flex margin-top-l margin-bottom-m">
                <h2 {...adorn(`home:clients-title`, EditableType.text)} className="col-12 underline text-middle large-title">
                    {t("clients-title")}
                </h2>
                {/* <div {...adorn(`home:clients-subtitle`, EditableType.text)} className="col-6 clients-subtitle">
                    {t("clients-subtitle")}
                </div> */}
            </div>
            <div className="clients-row">
                {
                    props.clients.map((client, i) => {
                        return (
                            <div key={`client-${i}`} className="client">
                                <img src={client} />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}
