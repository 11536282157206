export class CMS {
    static attachAdorners() {
        const beforeHeight = 37;
        const beforeWidth = 43;
    
        window.document.addEventListener("click", (ev) => {
            const isEditable = (ev.target as any)?.getAttribute("data-is-editable");
    
            if (isEditable) {
                const rect = (ev.target as any).getBoundingClientRect();
                const { clientX, clientY } = (ev as PointerEvent);
                const isInEditBox = clientX < rect.right && clientX > rect.right - beforeWidth && clientY > rect.top && clientY < rect.top + beforeHeight;
                // console.log(isInEditBox);
                if (isInEditBox) {
                    ev.preventDefault();
    
                    const t = (ev.target as any);
                    const detail: ArodernerEventDetail = {
                        key: t.getAttribute("data-editable-key"),
                        type: t.getAttribute("data-editable-type")
                    };
                    const adornerEvent = new CustomEvent(REQUEST_ADORNER_EVENT, {detail});
                    window.dispatchEvent(adornerEvent);
                }
            }
        });
    }
}

export const REQUEST_ADORNER_EVENT = "request-adorner";

export enum EditableType {
    video,
    text,
    image,
    gallery,
    project
}

export interface ArodernerEventDetail {
    type: EditableType,
    key: string
}

export function adorn(key: string, editableType: EditableType) {
    return  {} || {
        "data-is-editable": true,
        "data-editable-type": editableType,
        "data-editable-key": key
    };
}
