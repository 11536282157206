import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { adorn, EditableType } from "../common/adorners";
import { AssetsFetch } from "../data/assets";
import { VideoCarousel } from "../presenters/video-carousel";
import { VideoHolder, VideoModel } from "../presenters/video-holder";


export interface PhotoModel {
    src?: string;
    isPhoto: boolean;
    fullWidth: boolean;
    text?: string;
}

export interface ProjectModel {
    date: string;
    link: string;
    videos: VideoModel[];
    photos: PhotoModel[];
    isFeatured: true,
    thumbnail: string;
    key: string;
}

interface ProjectState {
    width?: number;
    height?: number;
}


export function Project() {
    const { projectId } = useParams();
    let ref = useRef<HTMLDivElement>(null);
    const [state, setState] = useState<ProjectState>({ width: 0, height: 0 });
    const [project, setProject] = useState<ProjectModel | null>(null);
    const { t } = useTranslation(["common", "projects"]);
    const calculateHeight = (width: number = window.innerWidth) => {
        return width * 9 / 16
    };

    useEffect(() => {
        AssetsFetch.getData().then(data => {
            const project = data.projects[projectId || ""];
            setProject(project);
        });
    }, [projectId])

    useEffect(() => {
        setState(Object.assign({}, state, { width: ref.current?.getBoundingClientRect().width, height: calculateHeight(ref.current?.getBoundingClientRect().width) }));
    }, [project]);

    useEffect(() => {
        function handleResize() {
            setState(Object.assign({}, state, {
                width: ref.current?.getBoundingClientRect().width,
                height: calculateHeight(ref.current?.getBoundingClientRect().width)
            }));
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const renderDetail = (translationKey: string, project: ProjectModel) => {
        const key = `${project.key}.${translationKey}`;
        const agency = t(key, { ns: "projects" });
        return key != agency && !!agency ? (
            <div className="col-4">
                <div className="details-title">{t(translationKey, { ns: "common" })}</div>
                <div>{agency}</div>
            </div>) : null;
    };

    const generateText = (photo: PhotoModel) => {
        return (<div className="col-12 text-middle project-gallery-subtitle">{photo.text}</div>);
    }

    const generateRow = (project: ProjectModel) => {
        const children: any[] = [];

        if (project.photos) {
            for (let i = 0; i < project.photos.length; i++) {
                const photo = project.photos[i];
                const nextPhoto = project.photos[i + 1]
                if (!photo.text) {
                    children.push((
                        <div className="inner-row row">
                            <div className={photo.fullWidth ? "col-12" : "col-6"}>
                                <img key={`project-photo-${i}`} src={photo.src}></img>
                            </div>
                            {
                                (() => {
                                    if (!photo.fullWidth && nextPhoto && !nextPhoto.fullWidth) {
                                        i++;
                                        return (<div className={nextPhoto.fullWidth ? "col-12" : "col-6"}>
                                                    <img key={`project-photo-${i + 1}`} src={nextPhoto.src}></img>
                                                </div>);
                                        }
                                    }
                                )()
                            }
                        </div>
                    ));
                } else if (photo.text) {
                    children.push(generateText(photo));
                }
            }
        }

        return (
            <div className="project-gallery row" {...adorn(`project-gallery-${projectId}`, EditableType.gallery)}>
                    { children }
                </div>
        );
    };


    return (
        <div className="container single-project" ref={ref}>
            {project && (<React.Fragment>
                <VideoCarousel videos={project.videos} width={state.width || 0} height={state.height || 0}></VideoCarousel>
                <div className="project-details row">
                    <div className="col-6">
                        <h3 className="project-title">{t(`${project.key}.title`, { ns: "projects" })}</h3>
                        {
                            (() => {
                                const value = t(`${project.key}.description`, { ns: "projects" });
                                return (
                                    value && value != `${project.key}.description` ?
                                    <div className="project-description">{value}</div> : null
                                );
                            })()
                        }
                    </div>
                    <div className="col-6">
                        { renderDetail("client", project) }
                        { renderDetail("agency", project) }
                        { renderDetail("photography", project) }
                        { renderDetail("mua", project) }
                        { renderDetail("styling", project) }
                        {project.link && <div className="col-4">
                            <div className="details-title">{t("project", { ns: "common" })}:</div>
                            <a target="_blank" href={project.link}>{t("check-here", { ns: "common" })}</a>
                        </div>}
                    </div>
                </div>
                
                { generateRow(project) }
            </React.Fragment>)}
        </div>
    );
}
