import ReactPlayer from 'react-player';

export interface VideoModel {
    url: string;
    width?: number;
    height?: number;
}

export function VideoHolder(props: VideoModel) {
    return (
        <ReactPlayer url={props.url} width={props.width} height={props.height}
                    stopOnUnmount={true} playing={false} controls={true}
                    transparent={"true"} config={{vimeo: {playerOptions: {transparent: true}}}} />
    );
}
