
export class AssetsFetch {
    private static header: any;
    private static home: any;
    private static projects: any;
    private static common: any;
    private static projectsData: any;
    private static generalData: any;
    private static rentals: any;
    private static services: any;
    private static team: any;

    static setResource(key: string, value: {en: string, bg: string}) {
        const root = key.split(":")[0] as string;
        const resourceKey = key.split(":")[1];
        (this as any)[root].en[resourceKey] = value.en;
        (this as any)[root].bg[resourceKey] = value.bg;
    }

    static async getTranslations() {
        const header = this.header = this.header || await (await fetch("./translations/header.json")).json();
        const home = this.home = this.home || await (await fetch("./translations/home.json")).json();
        const projects = this.projects = this.projects || await (await fetch("./translations/projects.json")).json();
        const common = this.common = this.common || await (await fetch("./translations/common.json")).json();
        const services = this.services = this.services || await (await fetch("./translations/services.json")).json();
        const team = this.team = this.team || await (await fetch("./translations/team.json")).json();
        
        return {
            header,
            home,
            projects,
            common,
            services,
            team
        }
    }

    static async getData() {
        const projects = this.projectsData = this.projectsData || await (await fetch("./data/projects.json")).json();
        const general = this.generalData = this.generalData || await (await fetch("./data/general.json")).json();
        const rentals = this.rentals = this.rentals || await (await fetch("./data/rentals.json")).json();

        return { projects, general, rentals };
    }
}
