export class FileHandler {
    private static dir: any;

    static async getDirectory() {
        const dirHandle = await (window as any).showDirectoryPicker();
        this.dir = dirHandle;
    }

    static async creatDir(existingHandle: any, newName: string) {
        return await existingHandle.getDirectoryHandle(newName, {
            create: true,
        });
    }

    static async createFile(existingHandle: any, fileName: string) {
        return await existingHandle.getFileHandle(fileName, { create: true });
    }

    static async handleDrop(e: DragEvent) {
        if (!e.dataTransfer?.items) {
            return console.error("nothing dropped");
        };

        const fileHandlesPromises = [...(e.dataTransfer.items as any)].filter(i => i.kind === "file").map(i => (i as any).getAsFileSystemHandle());

        for await (const handle of fileHandlesPromises) {
            if (handle.kind === 'directory') {
              console.log(`Directory: ${handle.name}`);
            } else {
              console.log(`File: ${handle.name}`);
            }
          }
    }
}