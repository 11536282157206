import React, { Fragment, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { VideoHolder, VideoModel } from "./video-holder";

import "react-responsive-carousel/lib/styles/carousel.min.css";
export interface VideoCarouselProps {
    videos: VideoModel[];
    width: number;
    height: number;
}

function Thumbnail(props: {videoSrc: string}) {
    const [thumb, setThumbnail] = useState("");

    useEffect(() => {
        let id;
        if(props && props.videoSrc.indexOf("vimeo") > -1) {
            id = props.videoSrc.replace("https://vimeo.com/showcase/9445888/video/", "").replace("https://vimeo.com/", "");
            
            fetch(`https://vimeo.com/api/v2/video/${id}.json`, {
                method: "GET",
            }).then(x => x.json()).then((x: any) => {
                setThumbnail(x[0].thumbnail_small);
            }).catch(e => {
                setThumbnail("");
            });
        } else if (props && props.videoSrc.indexOf("youtube") > -1) {
            id = props.videoSrc.replace("https://www.youtube.com/watch?v=", "");

            const t = `https://img.youtube.com/vi/${id}/default.jpg`;
            setThumbnail(t);
        }
    }, [props]);

    return (
        <div>
            {thumb && <img src={thumb} />}
        </div>
    );
}

export function VideoCarousel(props: VideoCarouselProps) {
    const renderThumb = () => {
        return props.videos.map((x, i) => {return (<Thumbnail key={`thumb-${i}`} videoSrc={x.url} ></Thumbnail>);});
    };

    if (props.videos.length == 1) {
        return (
            <React.Fragment>
                {props.videos.map((vid, i) => {
                    return (<VideoHolder key={`video-${i}`} url={vid.url} width={props.width} height={props.height}></VideoHolder>);
                })}
            </React.Fragment>
        );
    }

    const renderButton = (next: boolean) => {
        return (
            <div className={`video-carousel-button ${next ? "next" : "previous"}`}>
                <img src={next ? "/svg/next.svg" : "/svg/prev.svg"} />
            </div>
        );
    };

    return (
        <Fragment>

        {/* {renderButton(false)}
        {renderButton(true)} */}
        <Carousel infiniteLoop
            showStatus={false}
            showIndicators={false}
            renderThumbs={() => renderThumb()}
            showArrows = {props.videos.length > 1}
            onChange={() => {
                document.querySelectorAll('iframe').forEach(function(e){
                    e?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                    e?.contentWindow?.postMessage('{"method":"pause"}', '*');
                });
            }}>
            {props.videos.map((vid, i) => {
                return (<div>
                    <VideoHolder key={`video-${i}`} url={vid.url} width={props.width} height={props.height}></VideoHolder>
                </div>
                );
            })}
        </Carousel>
            </Fragment>  
    );
}