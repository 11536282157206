import { useTranslation } from "react-i18next"


export function ContactsPage() {
    const { t } = useTranslation("header");
    return (
        <div className="contacts container">

            <h1 className="underline text-middle large-title">
                Let’s talk
            </h1>
            <div className="contacts subtititle text-middle">
                Please don’t hesitate to contact us with any question you may have.
                We will get back to you as soon as possible.

            </div>

            <form action="https://getform.io/f/5a443cb3-52b6-4df6-8df7-23aa5ca950c9"  className="card-form" method="POST">
            <div className="input">
				<input type="text" placeholder="" autoComplete="name" name="full-name" className="input-field" required/>
				<label className="input-label">Full name</label>
			</div>
			<div className="input">
				<input type="email" placeholder="" autoComplete="email" name="email" className="input-field" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required/>
				<label className="input-label">Email</label>
			</div>
			<div className="input">
				<textarea placeholder="" className="input-field" name="message" required/>
				<label className="input-label">Message</label>
			</div>
			<div className="action">
				<button className="action-button">Submit</button>
			</div>
            </form>

            <div className="row margin-top-xl margin-bottom-l">

                {
                    [0, 1].map(x => {
                        return (
                            <div className="col-6">
                                <h3>{t(`contacts.${x}.name`, { ns: "header" })}</h3>
                                <div className="row">
                                    <div className="col-3">tel:</div>
                                    <div className="col-9">{t(`contacts.${x}.tel`, { ns: "header" })}</div>
                                </div>
                                <div className="row">
                                    <div className="col-3">email</div>
                                    <div className="col-9">{t(`contacts.${x}.email`, { ns: "header" })}</div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    )
}