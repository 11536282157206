import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export interface ServiceDetailModel {
    title: string;
    description: string;
}

export interface ServiceModel {
    title: string;
    details: ServiceDetailModel[];
}

export interface ServiceData {
    key: string;
    details: number;
}

export function ServicesPage(props: { general: { services: ServiceData[], servicesImage: string }}) {
    const { t } = useTranslation(["services", "home"]);
    return (
        <Fragment>
            <div className="slogan-home" style={{ backgroundImage: `url(${props.general.servicesImage})` }}></div>
            <div className="services container row">
                <h1 className="text-middle underline large-title row">
                    {t("capabilities-title", { ns: "home" })}
                </h1>
                {
                    props.general.services.map((s, j) => {
                        const key = s.key;
                        return (
                            <div className="col-4 service" key={`services-key-${j}`}>
                                <h2 className="service-title">
                                    {t(`${key}.title`, { ns: "services" })}
                                </h2>
                                <div className="details">
                                {
                                    (() => {
                                        const proj: any = [];
                                        for (let i = 0; i < s.details; i++) {
                                            proj.push((
                                                <div className="service-detail" key={`services-key-${j}-${i}`}>
                                                    <h4 className="service-detail-title">
                                                        {t(`${key}.details.${i}.title`, { ns: "services" })}
                                                    </h4>
                                                    <div className="service-detail-description">
                                                        {t(`${key}.details.${i}.description`, { ns: "services" })}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        return proj;
                                    })()
                                }
                                </div>
                            </div>);
                    })
                }
            </div>
        </Fragment>
    );
}