
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AssetsFetch } from '../data/assets';
import { ArodernerEventDetail, EditableType, REQUEST_ADORNER_EVENT } from './adorners';
import { EditProject } from './EditProject';
import { FileHandler } from './file-handler';
import { ReorderableGallery } from './ReorderableGallery';

Modal.setAppElement('#root');
export function EditorModal() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editAttributes, setEditorAttributes] = useState<ArodernerEventDetail | null>(null);
    const [currentValue, setCurrentValue] = useState<{ en: string, bg: string }>({ en: "", bg: "" });
    const [data, setData] = useState<{ projects: any, general: any } | null>(null);

    useEffect(() => {
        const listener = (ev: any) => {
            setModalIsOpen(true);
            const type = EditableType[EditableType[(ev.detail as ArodernerEventDetail).type] as keyof typeof EditableType];
            setEditorAttributes(Object.assign({}, ev.detail, { type }));
        };

        window.addEventListener(REQUEST_ADORNER_EVENT, listener);

        return () => {
            window.removeEventListener(REQUEST_ADORNER_EVENT, listener);
        };
    });

    useEffect(() => {
        if (editAttributes && editAttributes.type != EditableType.project) {
            AssetsFetch.getTranslations().then(data => {
                const root = editAttributes.key.split(":")[0];
                const resourseKey = editAttributes.key.split(":")[1];
                const res = (data as any)[root];
                setCurrentValue({
                    en: res.en[resourseKey],
                    bg: res.bg[resourseKey]
                });
            })
        }

        if (editAttributes?.type === EditableType.gallery || editAttributes?.type === EditableType.project) {
            AssetsFetch.getData().then(data => {
                setData(data);
            })
        }
    }, [editAttributes])

    const setValue = (val: string, key: string) => {
        setCurrentValue(Object.assign({}, currentValue, { [key]: val }));
    };

    const requestClose = () => {
        setModalIsOpen(false);
        setCurrentValue({ en: "", bg: "" });
        setEditorAttributes(null);
    };


    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={() => { }}
            onRequestClose={() => { requestClose() }}
            style={{ content: { zIndex: 10000 }, overlay: { zIndex: 10000 } }}
            contentLabel="Example Modal">
            {editAttributes?.type == EditableType.text && (
                <div>
                    <div>{`editing ${editAttributes.key}`}</div>
                    <div>
                        <input type="text" value={currentValue.en} onChange={(ev) => { setValue(ev.target.value, "en") }} />
                    </div>
                    <div>
                        <input type="text" value={currentValue.bg} onChange={(ev) => { setValue(ev.target.value, "bg") }} />
                    </div>
                </div>
            )}

            {editAttributes?.type == EditableType.gallery && data?.projects && (() => {

                const projectId = editAttributes?.key.replace("project-gallery-", "") as string;
                const photos = data.projects[projectId].photos;
                return (<div onDragOver={(e) => e.preventDefault()} onDrop={(e) => {
                    e.preventDefault();
                    FileHandler.handleDrop(e.nativeEvent);
                    console.log(e);
                }}>
                    <ReorderableGallery photos={photos} ></ReorderableGallery>
                </div>);
            })()
            }

            {
                editAttributes?.type == EditableType.project && data?.projects && (
                    () => {
                        const projectId = editAttributes?.key.replace("project-edit-", "") as string;
                        const project = data.projects[projectId];
                        return (
                            <EditProject project={project} ></EditProject>
                        );
                    }
                )()
            }

            <button onClick={() => {
                if (editAttributes) {
                    AssetsFetch.setResource(editAttributes.key, currentValue);
                    // save and refresh app
                    saveFile();

                }
            }
            }>Save</button>
        </Modal>
    );
}

async function saveFile() {

    // create a new handle
    const newHandle = await (window as any).showSaveFilePicker();

    // create a FileSystemWritableFileStream to write to
    const writableStream = await newHandle.createWritable();

    // write our file
    // await writableStream.write(imgBlob);

    // close the file and write the contents to disk.
    await writableStream.close();
}

