import { Fragment } from "react";

export interface RentalsModel {
    camera: RentalCategory;
    lighting: RentalCategory;
    grip: RentalCategory;
    sound: RentalCategory;
}

export interface RentalCategory {
    title: string;
    subcategories: RentalSubCategory[];
}

export interface RentalSubCategory {
    title: string;
    products: Product[];
}

export interface Product {
    name: string;
    quantity: number;
    price: number;
    photo: string;
}

export function Rental(props: { data: RentalCategory[] }) {
    return (
        <div className="container">
            <h1 className="text-middle underline large-title row">Rental</h1>
            {props.data?.map((x: RentalCategory) => {
                return (
                    <div className="rental-category">
                        <h2 className="title">{x.title}</h2>
                        {
                            x.subcategories.map((s: RentalSubCategory) => {
                                return (
                                    <div>
                                        <h3 className="subtitle">{s.title}</h3>
                                        {
                                            s.products.map((p: Product) => {
                                                return (
                                                    <div className="rental-product row">
                                                        <img src={p.photo} alt={p.name} />
                                                        <div className="name">{p.name}</div>
                                                        <div className="quantity">{p.quantity} available</div>
                                                        <div className="price">{p.price} BGN</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                )
            })}
        </div>
    );
}
